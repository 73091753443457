@import (reference) "./core/standards.less";

.body-404{
    min-height:60vh;
    background-color: @color-palette-tile;
    padding:.px-to-rem(15)[@rem];
    position:relative;
    display:flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .layer-2{
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:2;
        object-fit:cover;
        object-position: center;
    }

    .layer-3{
        position:relative;
        z-index:3;
        width: 84%;
        margin:auto;
        color:white;

        h1{
            font-family: "Tiempos Headline", "Nunito Sans", sans-serif;
            font-size:22vw;
            margin:auto;
            text-align:center;
        }

        .content-2{
            align-self:center;
            text-align:center;
            display:flex;
            flex-flow:column nowrap;
            align-items: center;
            justify-content: center;
            font-size:2.6vw;

            h2{
                font-family: "Tiempos Headline", "Nunito Sans", sans-serif;
                font-size: 3.2vw;
                margin:auto;
            }
        }
    }

}