.body-404 {
  min-height: 60vh;
  background-color: #0ba993;
  padding: calc(calc(15 / 16) * 1rem);
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.body-404 .layer-2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  object-fit: cover;
  object-position: center;
}
.body-404 .layer-3 {
  position: relative;
  z-index: 3;
  width: 84%;
  margin: auto;
  color: white;
}
.body-404 .layer-3 h1 {
  font-family: "Tiempos Headline", "Nunito Sans", sans-serif;
  font-size: 22vw;
  margin: auto;
  text-align: center;
}
.body-404 .layer-3 .content-2 {
  align-self: center;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 2.6vw;
}
.body-404 .layer-3 .content-2 h2 {
  font-family: "Tiempos Headline", "Nunito Sans", sans-serif;
  font-size: 3.2vw;
  margin: auto;
}
